.toggle {
    position: relative;

    * {
        --main-timing: .5s;
        box-sizing: border-box;
        transition:
            color var(--main-timing),
            background var(--main-timing),
            transform var(--main-timing);
    }

    .box {
        display: inline-block;
        background: #999;
        height: 18px;
        width: 40px;
        border-radius: 10px;
        margin-top: 4px;
    }

    .switch {
        position: absolute;
        top: -7px;
        left: 0px;
        display: inline-block;
        background: white;
        border: solid 3px #999;
        height: 24px;
        width: 24px;
        border-radius: 50%;
    }

    .off,
    .on {
        text-transform: uppercase;
        font-weight: 700;
        color: #999;

    }

    input[type=checkbox] {
        display: none;
        margin-top: 10px;

        &:checked~.on,
        &:not(:checked)~.off {
            display: none;
        }

        &:checked~.switch {
            transform: translateX(16px);
            background: #333;
        }
    }
}