@import './node_modules/bootstrap-scss/bootstrap';
@import url("https://use.typekit.net/cfg0pqs.css");
@import 'variables';
@import 'darkmode-toggle';
@import 'pant';
@import 'input-label';
@import './node_modules/photoswipe/src/css/main';
@import './node_modules/photoswipe/src/css/default-skin/default-skin';

*:not([class^="pswp"]) {
    --main-timing: .5s;
    box-sizing: border-box;
    transition: 
    color var(--main-timing),
    background var(--main-timing),
    transform var(--main-timing),
    opacity var(--main-timing),
    height var(--main-timing),
    width var(--main-timing),
    padding var(--main-timing);
    outline: none;
}

body {
    background: var(--main-bg-color);
    color: var(--main-font-color);
    font-family: mr-eaves-xl-modern, sans-serif;
    font-weight: 400;
    font-style: normal;

    h4 {
        font-size: 1.25rem;
        font-weight: 300;
    }

    nav {
        svg#logo {
            width: 60px;
            &:hover g#Layer_1 g {
                animation-name: pant;
                animation-direction: alternate;
                animation-duration: .2s;
                animation-iteration-count: infinite;
            }
        }
        .navbar-toggler {
            background-color: transparent;
            padding: 10px 15px;    
            margin: 0;
            border: 1px solid transparent;
            border-radius: 5px;
            outline: none;
            overflow: hidden;
            transition: all 1s;
            #navsvg{
                fill: var(--main-bg-color);
                display: block;
                margin: 0;
                width: 20px;
                g {
                    transition: all 0.3s;
                    &:nth-child(1){
                        transform-origin: 1.5px 1.5px;
                    }
                    &:nth-child(3){
                        transform-origin: 1.5px 13.5px;
                    }
                }
            }
            &.close-btn {
                opacity: 1;
                #navsvg{
                    fill: var(--secondary-accent-color);
                    g {
                        &:nth-child(1){
                            transform: translate(2.5px, 0) rotate(45deg) ;
                        }
                        &:nth-child(2){
                            opacity: 0;
                        }
                        &:nth-child(3){
                            transform: translate(2.5px, 0) rotate(-45deg) ;
                        }
                    }
                }
            }
        }          
        .collapse.navbar-collapse {
            display: block;
            position: fixed;
            overflow: hidden;
            top: 0;
            left: 0;
            height: 0%;
            width: 100vw;
            z-index:-1;
            background-color: var(--main-bg-color);
            .nav-item {
                a {
                    font-size: 1.55rem;
                    font-weight: 300;
                    color: var(--main-font-color);
                    &.nav-link {
                        padding: 2vh 1vw;
                    }
                }
            }
            &.show {
                height: 100vh;
                padding: 86px 10vw;
                .nav-item {
                    border-bottom: 1px solid var(--main-font-color);
                    &.d-block {
                        border-bottom: none;
                    }
                }
            }
            &:not(.show) {
                padding: 0px 10vw;
                a{
                    opacity: 1;
                }
            }
        }
    }
    header, section {
        position: relative;
        min-height: 100vh;
        padding: 0;
        padding-top: 86px;
        padding-bottom: 4rem;
        scroll-snap-align: start;
        .skewed-bg {
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            background: $main-color;
            // margin: -10vw -10vw 0 -10vw;
            min-height: 70vh;
            width: 100%;
            transform: skew(0deg, -($degrees));
            transform-origin: left;
            .content {
                transform-origin: left;
                transform: skew(0deg, $degrees);
            }
        }
    }
    header {
        display: grid;
        grid-template-columns: 1fr 1fr 3fr 1fr 1fr;
        grid-template-rows: 15vh calc(85vh - 88px) auto;
        justify-items: center;
        svg#text-logo {
            display: block;
            grid-column-start: 3;
            grid-column-end: 4;
            grid-row-start: 1;
        }
        img#obi-front-shot {
            display: block;
            grid-column-start: 1;
            grid-column-end: 6;
            grid-row-start: 2;
            width: 100%;
            height: 60vh;
            object-fit: cover;
            object-position: left;
        }
        h1 {
            grid-column-start: 2;
            grid-column-end: 5;
            grid-row-start: 3;
        }
        h4 {
            text-align: center;
            grid-column-start: 2;
            grid-column-end: 5;
            margin-bottom: 1.25rem;
            &:nth-of-type(1) {
                grid-row-start: 4;
            }
            &:nth-of-type(2) {
                grid-row-start: 5;
            }
            &:nth-of-type(3) {
                grid-row-start: 6;
            }
        }
    }
    section#stud-dog{
        background-color: var(--main-accent-color);
        display:grid;
        grid-template-columns: 1fr 5fr 1fr;
        grid-template-rows: 5fr 1fr 3fr;
        justify-items: center;
        align-items: top;
        padding-top: 0;
        h1 {
            color: var(--main-font-color);
            font-size: 20vw;
            z-index: 1;
            text-transform: uppercase;
            font-weight: 900;
            grid-column-start: 1;
            grid-column-end: 4;
            grid-row-start: 2;
        }
        video {
            grid-column-start: 1;
            grid-column-end: 4;
            grid-row-start: 1;
            height: 100%;
            width: 100%;

            object-fit: cover;
            object-position: center;
        }
        div#studwrapper{
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 3;
        }
        // h4 {
        //     &:nth-of-type(1) {
        //         grid-column-start: 2;
        //         grid-column-end: 3;
        //         grid-row-start: 3;
        //     }
        //     &:nth-of-type(2) {
        //         grid-column-start: 2;
        //         grid-column-end: 3;
        //         grid-row-start: 4;
        //     }
        //     &:nth-of-type(3) {
        //         grid-column-start: 2;
        //         grid-column-end: 3;
        //         grid-row-start: 5;
        //     }
        // }
    }
    section#gallery {
        h1 {
            text-align: center;
            margin-bottom: 5vw;
        }
        .skewed-bg {
            position:relative;
            z-index: 0;
            background-color: #000;
            .content {
                padding: 5vh
            }
        }
        .my-gallery{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: auto;
            grid-gap: 10px;        
            figure {
                margin: 0;
                height:100%;
                width:100%;
                &:nth-child(1) {
                    grid-column: span 3;
                    grid-row: span 2;
                }
                &:nth-child(3) {
                    grid-column: span 2;
                }
                &:nth-child(4) {
                    grid-row: span 2;
                }
                &:nth-child(7) {
                    grid-column: span 2;
                }
                &:nth-child(9) {
                    grid-column: span 2;
                }
                &:nth-child(10),&:nth-child(11) {
                    display: none;
                }
                a {
                    height:100%;
                    width:100%;    
                    img {
                        height:100%;
                        width:100%;
                        object-fit: cover;
                    }
                }

            }
        }
    }
    section#contact {
        background-color: var(--main-bg-color);
        form {
            display: grid;
            grid-template-columns: 1fr 5fr 1fr;
            grid-template-rows: auto;
            justify-items: center;
            h1 {
                text-align: center;
                grid-column-start: 2;
            }
            .input {
                input {
                    border-color: var(--main-font-color);
                }
                &:nth-of-type(1){
                    grid-column-start: 2;
                    grid-row-start: 2;
                }
                &:nth-of-type(2){
                    grid-column-start: 2;
                    grid-row-start: 3;
                }
                &:nth-of-type(3){
                    grid-column-start: 2;
                    grid-row-start: 4;
                }
            }
            #submit {
                margin-top: 3rem;
                width: 100%;
                padding: 1rem;
                background-color: transparent;
                border: 1px solid var(--main-font-color);
                border-radius: 0.5rem;
                color: var(--main-font-color);
                grid-column-start: 2;
                grid-row-start: 5;
            }
        }
    }
    &.scroll {
        nav {
            // background-color: var(--main-bg-color);
            #navsvg{
                fill: var(--secondary-accent-color);
            }
            svg#logo {
                width: 40px;
            }
        }
        section, header {
            padding-top: 66px;
        }
    }
}

@media (min-width: 768px) and (max-width: 1199px){
    html {
        scroll-behavior: smooth;
    }
    body {   
        nav {
            .collapse.navbar-collapse{
                background-color: transparent;
                z-index: auto;
                display: block;
                position: relative;
                top: auto;
                left: auto;
                height: auto;
                width: auto;
                transition: none;
                &.show, &:not(.show) {
                    padding: 0%;
                }
                .nav-item {
                    a {
                        font-size: 1.15rem;
                        font-weight: 300;
                        position: relative;
                        display: inline-block;
                        &:after {
                            position: absolute;
                            top: 90%;
                            left: 0;
                            width: 0%;
                            height: 1px;
                            background: var(--main-font-color);
                            content: '';
                            opacity: 0;
                            transition: opacity 0.3s, width 0.3s;
                        }
                        &:hover:after {
                            width: 100%;
                            opacity: 1;
                        }
                    }
                }
    
            }
        }
        header, section {
            .skewed-bg {
                min-height: 42vw;
                .content {
                    padding-bottom: 15vw;
                }
            }
        }
        header {
            display: grid;
            // grid-template-columns: repeat(7, 1fr);
            grid-template-columns: minmax(50px, auto) repeat(5, minmax(100px, 220px)) minmax(50px, auto);
            grid-template-rows: 10vh auto;
            gap: 1.25rem; 
            svg#text-logo {
                display: block;
                grid-column-start: 2;
                grid-column-end: 4;
                grid-row-start: 1;
            }
            img#obi-front-shot {
                display: block;
                grid-column-start: 4;
                grid-column-end: 7;
                grid-row-start: 1;
                grid-row-end: 3;
                width: 100%;
                height: auto;
            }
            h1 {
                text-align: right;
                grid-row-start: 3;
                grid-column-start: 2;
                grid-column-end: 4;
                margin-top: -1rem;
            }
            h4 {
                font-size: 1.25rem;
                font-weight: 300;
                text-align: left;
                margin-bottom: 0rem;
                // margin-top: 1rem;
                &:nth-of-type(1) {
                    grid-row-start: 3;
                    grid-column-start: 4;
                    grid-column-end: 7;
                }
                &:nth-of-type(2) {
                    grid-row-start: 4;
                    grid-column-start: 4;
                    grid-column-end: 7;
                }
                &:nth-of-type(3) {
                    grid-row-start: 5;
                    grid-column-start: 4;
                    grid-column-end: 7;
                }
            }
        }
        section#gallery {
            .skewed-bg {
                .content {
                    padding-bottom: 10vw;
                }
            }
            .my-gallery{
                display: grid;
                grid-template-columns: 3fr 1fr 1fr ;
                grid-template-columns: repeat(5, minmax(100px, 1fr));
                grid-gap: 5vw;
                grid-gap: 10px;
                align-items: start;
                justify-items: center;
                margin: auto;
                width: 100%;
                max-width: 1024px;
                // grid-template-areas: 
                //     "header main1"
                //     "header main2";
            
                figure {
                    margin: 0;
                    height:100%;
                    width:100%;
                    &:nth-child(1) {
                      grid-column: span 3;
                      grid-row: span 2;
                    }
                    a {
                        height:100%;
                        width:100%;    
                        img {
                            height:100%;
                            width:100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
        &.scroll {
            nav {
                background-color: var(--main-bg-color);
                #navsvg{
                    fill: var(--secondary-accent-color);
                }
                svg#logo {
                    width: 60px;
                }
            }
            section, header {
                padding-top: 86px;
            }
        }    
    }
}

@media (min-width: 768px) and (max-width: 1199px) and (orientation: landscape){
//     body {
//         header, section {
//             .skewed-bg {
//                 min-height: 42vw;
//                 .content {
//                     padding-bottom: 15vw;
//                 }
//             }
//         }
//     }
}

@media (min-width: 768px) and (max-width: 1199px) and (orientation: portrait){
//     body {
//         header, section {
//             .skewed-bg {
//                 min-height: 42vw;
//                 .content {
//                     padding-bottom: 15vw;
//                 }
//             }
//         }
//     }
}


@media (min-width: 1200px){
    html {
        scroll-behavior: smooth;
    }
    body {   
        nav {
            .collapse.navbar-collapse{
                background-color: transparent;
                z-index: auto;
                display: block;
                position: relative;
                top: auto;
                left: auto;
                height: auto;
                width: auto;
                transition: none;
                &.show, &:not(.show) {
                    padding: 0%;
                }
                .nav-item {
                    a {
                        font-size: 1.15rem;
                        font-weight: 300;
                        position: relative;
                        display: inline-block;
                        &:after {
                            position: absolute;
                            top: 90%;
                            left: 0;
                            width: 0%;
                            height: 1px;
                            background: var(--main-font-color);
                            content: '';
                            opacity: 0;
                            transition: opacity 0.3s, width 0.3s;
                        }
                        &:hover:after {
                            width: 100%;
                            opacity: 1;
                        }
                    }
                }
    
            }
        }
        header, section {
            .skewed-bg {
                min-height: 55vh;
                .content {
                    padding-bottom: 15vw;
                }
            }
        }
        header {
            display: grid;
            // grid-template-columns: repeat(7, 1fr);
            grid-template-columns: auto repeat(5, minmax(100px, 220px)) auto ;
            grid-template-rows: 10vh 42vh auto;
            gap: 2.25rem; 
            svg#text-logo {
                width: 100%;
                display: block;
                grid-column-start: 2;
                grid-column-end: 4;
                grid-row-start: 1;
            }
            img#obi-front-shot {
                display: block;
                grid-column-start: 4;
                grid-column-end: 7;
                grid-row-start: 1;
                grid-row-end: 3;
                width: 100%;
                height: auto;
            }
            h1 {
                grid-row-start: 3;
                grid-column-start: 3;
                grid-column-end: 4;
            }
            h4 {
                font-size: 1.05rem;
                font-weight: 300;
                text-align: left;
                margin-bottom: 0rem;
                margin-top: 1rem;
                &:nth-of-type(1) {
                    grid-row-start: 3;
                    grid-column-start: 4;
                    grid-column-end: 5;
                }
                &:nth-of-type(2) {
                    grid-row-start: 3;
                    grid-column-start: 5;
                    grid-column-end: 6;
                }
                &:nth-of-type(3) {
                    grid-row-start: 3;
                    grid-column-start: 6;
                    grid-column-end: 7;
                }
            }
        }
        section#stud-dog{
            height: 100vh;
            grid-template-columns: 3fr 1fr 1fr 1fr;
            grid-template-rows: repeat(3, 1fr);
            justify-items: center;
            align-items: center;
            padding:0;
            h1 {
                font-size: 10vw;
                text-transform: uppercase;
                font-weight: 900;
                z-index: 1;
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: 1;
                grid-row-end: 2;
            }
            video {
                border-radius: 1px;
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row:1/-1;
                height: 100%;
                width: 100%;
    
                object-fit: cover;
                object-position: center;
            }
            div#studwrapper{
                grid-column-start: 3;
                grid-column-end: 4;
                grid-row-start: 2;
                grid-row-end: 3;
                // h4 {
                //     &:nth-of-type(1) {
                //         grid-column-start: 3;
                //         grid-column-end: 4;
                //         grid-row-start: 2;
                //         grid-row-end: 3;
                //     }    
                //     &:nth-of-type(2) {
                //         grid-column-start: 3;
                //         grid-column-end: 4;
                //         grid-row-start: 3;
                //         grid-row-end: 4;
                //     }    
                //     &:nth-of-type(3) {
                //         grid-column-start: 3;
                //         grid-column-end: 4;
                //         grid-row-start: 4;
                //         grid-row-end: 5;
                //     }    
                // }
            }
        }
        section#gallery {
            .skewed-bg {
                .content {
                    padding-bottom: 10vw;
                }
            }
            .my-gallery{
                display: grid;
                grid-template-columns: repeat(6, minmax(100px, 1fr));
                grid-auto-rows: 1fr;
                grid-gap: 15px;
                align-items: start;
                justify-items: center;
                margin: auto;
                width: 100%;
                max-width: 1200px;
                // grid-template-areas: 
                //     "header main1"
                //     "header main2";
            
                figure {
                //     margin: 0;
                    // height:100%;
                    // width:100%;
                //     &:nth-child(1) {
                //         grid-column: span 3;
                //         grid-row: span 2;
                //     }
                //     &:nth-child(3) {
                //         grid-column: span 2;
                //         // grid-row: span 2;
                //     }
                //     &:nth-child(4) {
                //         // grid-column: span 2;
                //         grid-row: span 2;
                //     }
                //     &:nth-child(7) {
                //         grid-column: span 2;
                //         // grid-row: span 2;
                //     }
                //     &:nth-child(9) {
                //         grid-column: span 2;
                //         // grid-row: span 2;
                //     }
                //     &:nth-child(10),&:nth-child(11) {
                //         display: none;
                //         // grid-column: span 2;
                //         // grid-row: span 2;
                //     }
                }
            }
        }
        section#contact {
            form {
                grid-template-columns: 2fr 1fr 1fr 2fr;
                gap: 2rem;
                h1 {
                    text-align: center;
                    grid-column-start: 2;
                    grid-column-end: 4;
                }
                .input {
                    &:nth-of-type(1){
                        grid-column-start: 2;
                        grid-row-start: 2;
                    }
                    &:nth-of-type(2){
                        grid-column-start: 3;
                        grid-row-start: 2;
                    }
                    &:nth-of-type(3){
                        grid-column-start: 2;
                        grid-column-end: 4;
                        grid-row-start: 3;
                    }
                }
                #submit {
                    grid-column-start: 2;
                    grid-column-end: 4;
                    grid-row-start: 4;
                }
            }
        }
        &.scroll {
            nav {
                background-color: var(--main-bg-color);
                #navsvg{
                    fill: var(--secondary-accent-color);
                }
                svg#logo {
                    width: 60px;
                }
            }
            section, header {
                padding-top: 86px;
            }
        }    
    }
}