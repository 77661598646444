$main-color: #ffb911;
$degrees: 4deg;

body {
    --main-bg-color:#fff;
    --middle-bg-color: #666;
    --main-font-color:#222;
    --main-accent-color:#ffb911;
    --secondary-accent-color:#E65225;
}

body.darkmode {
    --main-bg-color:#222;
    --main-font-color:#fff;
    --main-accent-color:#ffb911;
    --secondary-accent-color:#E65225;
}
