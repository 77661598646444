form {
    width: 100%;
    textarea:focus, input:focus{
        outline: none;
    }
}

.input{
    width: 100%;


    input:focus ~ .floating-label,
    input:not(:focus):valid ~ .floating-label{
    top: 2px;
    bottom: 10px;
    left: 20px;
    font-size: 11px;
    opacity: 1;
    }

    position: relative;
    input{
        font-size: 14px;
        width: 100%;
        height: 60px;
        padding: 16px;
        border: none;
        border-bottom: 1px solid black;
        background-color: transparent;
    }
    .floating-label {
        position: absolute;
        pointer-events: none;
        left: 20px;
        top: 18px;
        transition: 0.2s ease all;
    }  
}

